<template>
  <div class="home">
    <el-container>
      <el-aside width="200px">
        <!-- default-active="2" 设置默认选择项目-->
        <el-menu
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#303133"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <!-- <el-menu-item
            index="1"
            @click="$router.push('/configuration')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">定制管理1</span>
          </el-menu-item> -->
          <el-menu-item
            index="6"
            @click="$router.push('/template')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">定制管理</span>
          </el-menu-item>
          <el-menu-item
            index="2"
            @click="$router.push('/list')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">定制列表</span>
          </el-menu-item>
          <el-menu-item
            index="3"
            @click="$router.push('/user')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item
            index="4"
            @click="$router.push('/region')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">大区管理</span>
          </el-menu-item>
          <!-- <el-menu-item
            index="5"
            @click="$router.push('/department')"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">部门管理</span>
          </el-menu-item> -->
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>


<style lang="less" scoped>
.home {
  height: 100%;
  .el-container {
    height: 100%;
  }
  .el-aside {
    background-color: #303133;
  }
  /deep/.el-menu-item-group__title {
    color: #fff;
  }
  .el-icon-menu {
    color: #fff;
  }
  .el-main {
    // background-color: #ebeef5;
    padding: 0;
  }
}
</style>

