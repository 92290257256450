<template>
  <div class="list">
    <el-container>
      <el-header>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input
              placeholder=""
              v-model="applicant"
            >
              <template slot="prepend">需求提交人</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              placeholder=""
              v-model="director"
            >
              <template slot="prepend">营销主管</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              placeholder=""
              v-model="ProductDirector"
            >
              <template slot="prepend">产品主管</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input
              placeholder=""
              v-model="ProductManager"
            >
              <template slot="prepend">产品经理</template>
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="5">
            <el-select
              v-model="datevalue"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dateOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="9">
            <div class="block">
              <el-date-picker
                v-model="dateVal"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change='changeDate'
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="Hardware"
              placeholder="请选择硬件品类"
            >
              <el-option
                v-for="item in HardwareOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="Software"
              placeholder="请选择软件类型"
            >
              <el-option
                v-for="item in SoftwareOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select
              v-model="Region"
              placeholder="请选择大区"
            >
              <el-option
                v-for="item in RegionOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-input
              placeholder="请输入项目名称或客户名称"
              v-model="entryName"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-select
              v-model="statusValue"
              multiple
              placeholder="请选择项目状态"
            >
              <el-option
                v-for="item in statusOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <button @click="search">开始搜索</button>
          </el-col>
          <el-col :span="5">
            <button @click="exportlist">导出结果</button>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column
            prop="number"
            label="项目编号"
          >
          </el-table-column>
          <el-table-column
            prop="userName"
            label="售前申请人"
          >
          </el-table-column>
          <el-table-column
            prop="entryNa"
            label="项目名称"
          >
          </el-table-column>
          <el-table-column
            prop="customerName"
            label="客户名称"
          >
          </el-table-column>
          <el-table-column
            prop="region"
            label="所属大区"
          >
          </el-table-column>
          <el-table-column
            prop="category"
            label="硬件产品品类"
          >
          </el-table-column>
          <el-table-column
            prop="Customtype"
            label="软件定制类型"
          >
          </el-table-column>
          <el-table-column
            prop="date"
            label="申请时间"
          >
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot:default="{row}">
              <p @click="toXq(row.id)">详情</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pagesize"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      applicant: "", //申请人
      director: "", // 售前主管
      ProductDirector: "", // 产品主管
      ProductManager: "", // 产品经理
      entryName: "", // 项目名称
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        } // 选择
      ],
      dateOption: [
        {
          value: "1",
          label: "按申请时间查询"
        }
      ], // 时间
      HardwareOption: [
        {
          value: "ECL",
          label: "ECL"
        },
        {
          value: "ECG",
          label: "ECG"
        },
        {
          value: "ECE",
          label: "ECE"
        },
        {
          value: "ECP",
          label: "ECP"
        },
        {
          value: "ECB",
          label: "ECB"
        }
      ], // 硬件
      SoftwareOption: [
        {
          value: "X86",
          label: "X86"
        },
        {
          value: "ARM",
          label: "ARM"
        }
      ], // 软件类型
      RegionOption: [
        {
          value: "3",
          label: "华南区"
        },
        {
          value: "1",
          label: "华北区"
        },
        {
          value: "2",
          label: "华东区"
        },
        {
          value: "4",
          label: "华西区"
        }
      ], // 大区
      statusOption: [
        {
          value: "pre_sale_shelve",
          label: "项目搁置"
        },
        {
          value: "pre_sale_confirm_complete",
          label: "确认成单"
        },
        {
          value: "pre_sale_confirm_programme",
          label: "方案认可"
        },
        {
          value: "pre_sale_draft_confirm_submit",
          label: "提交方案到客户"
        },
        {
          value: "pre_sale_draft_confirm",
          label: "需求提交人确认状态"
        },
        {
          value: "product_manager_approval",
          label: "产品经理审核"
        },
        {
          value: "product_supervisor_approval",
          label: "产品主管审核"
        },
        {
          value: "pre_sale_supervisor_approval",
          label: "营销主管审核"
        },
        {
          value: "pre_sale_draft",
          label: "需求提交人草稿状态（被驳回）"
        }
      ], // 项目状态
      value: "", // 确认选择的值
      datevalue: "", // 时间筛选的值
      Hardware: "", // 硬件筛选的值
      Software: "", // 软件筛选的值
      Region: "", // 大区筛选的值
      statusValue: "", // 项目状态的值
      dateVal: [], // 日期选择
      dateList: [],
      tableData: [],
      pagesize: 10,
      total: 0, // 总条数
      pagenum: 1 // 当前页
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    exportlist() {
      // location.href = 'http://scheme.dnopen.com/api/backend/export/subject'
      window.open(
        `https://iotapi.joinusad.com/api/backend/export/subject?start_time=${
          this.dateList[0]
        }&end_time=${this.dateList[1]}&name=${this.entryName}&member=${
          this.applicant
        }&pre_sale_supervisor=${this.director}&product_supervisor=${
          this.ProductDirector
        }&product_manager=${this.ProductManager}&region_id=${
          this.Region
        }&status=${this.statusValue.toString()}&pinlei=${this.Hardware}&rjlx=${
          this.Software
        }`
      );
    },
    async getlist() {
      const res = await this.axios.get(`/api/backend/project`, {
        params: {
          per_page: this.pagesize, // 一页显示多少条
          page: this.pagenum, // 当前页
          start_time: this.dateList[0],
          end_time: this.dateList[1],
          name: this.entryName,
          member: this.applicant,
          pre_sale_supervisor: this.director,
          product_supervisor: this.ProductDirector,
          product_manager: this.ProductManager,
          region_id: this.Region,
          status: this.statusValue.toString(),
          pinlei: this.Hardware,
          rjlx: this.Software
        }
      });
      console.log(res);
      if (res.status_code === 200) {
        this.tableData = [];
        res.data.data.forEach(item => {
          this.tableData.push({
            number: item.number,
            userName: item.member_name,
            entryNa: item.title,
            customerName: item.name,
            region: item.region,
            category: item.pinlei,
            Customtype: item.rjlx,
            date: item.created_at,
            id: item.id
          });
        });
        this.total = res.data.total;
        console.log(this.tableData);
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pagenum = val;
      this.getlist();
    },
    // 详情
    toXq(id) {
      this.$router.push({ path: `/customdetails?id=${id}` });
    },
    // 时间处理
    changeDate() {
      this.dateList = [];
      this.dateVal.forEach(item => {
        item = this.moment(item).format("YYYY-MM-DD");
        this.dateList.push(item);
        console.log(this.dateList);
      });
    },
    // 搜索
    async search() {
      const res = await this.axios.get(
        `/api/backend/project?start_time=${this.dateList[0]}&end_time=${
          this.dateList[1]
        }&name=${this.entryName}&member=${this.applicant}&pre_sale_supervisor=${
          this.director
        }&product_supervisor=${this.ProductDirector}&product_manager=${
          this.ProductManager
        }&region_id=${
          this.Region
        }&status=${this.statusValue.toString()}&pinlei=${this.Hardware}&rjlx=${
          this.Software
        }&per_page=10`
      );
      console.log(res);
      if (res.status_code === 200) {
        this.page = 1;
        this.tableData = [];
        res.data.data.forEach(item => {
          this.tableData.push({
            number: item.number,
            userName: item.member_name,
            entryNa: item.title,
            customerName: item.name,
            region: item.region,
            category: item.pinlei,
            Customtype: item.rjlx,
            date: item.created_at,
            id: item.id
          });
        });
        this.total = res.data.total;
        console.log(this.tableData);
      } else {
        this.$message.error(res.error);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.list {
  .el-header {
    // background-color: skyblue;
    padding-top: 10px;
    height: 100% !important;
    .el-row {
      margin-bottom: 10px;
      .el-select {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
    }
    /deep/.el-input-group__prepend {
      background-color: #fff;
    }
    button {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      color: #fff;
      background: linear-gradient(90deg, #09aaff, #2685ff);
      border: none;
      cursor: pointer;
    }
  }
  .el-main {
    // background-color: hotpink;
    /deep/.el-table th {
      background-color: #edf2f6;
    }
    p {
      color: #09aaff;
      cursor: pointer;
    }
    .el-pagination {
      margin-top: 15px;
      float: right;
    }
  }
}
</style>



