<template>
  <div class="user">
    <div class="user-header">
      <h2>用户列表</h2>
      <!-- <el-button type="primary">新增</el-button>
      <el-button type="success">启用</el-button>
      <el-button type="warning">禁用</el-button>
      <el-button type="danger">删除</el-button>
      <el-button type="info">导出</el-button> -->
      <!-- <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="search"
      >
      </el-input> -->
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
      >
      </el-table-column>
      <el-table-column label="UID">
        <template slot-scope="scope">{{ scope.row.uid }}</template>
      </el-table-column>
      <!-- <el-table-column
        prop="weixinName"
        label="微信昵称"
      >
      </el-table-column> -->
      <el-table-column
        prop="username"
        label="姓名"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="group"
        label="部门"
      >
      </el-table-column>
      <el-table-column
        prop="date"
        label="注册时间"
      >
      </el-table-column>
      <el-table-column
        prop="region"
        label="所在大区"
      >
      </el-table-column>
      <el-table-column
        prop="email"
        label="邮箱"
      >
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot:default="{row}">
          <el-button type="primary" round size='small' v-if="row.status == 0" @click="changExamine(row.uid)">审核</el-button>
          <el-button type="danger" round size='small' v-if="row.status == 0" @click="changeReject(row.uid)">驳回</el-button>
          <el-button type="danger" round size='small' v-if="row.status == 1" @click="changeDisable(row.uid)">禁用</el-button>
          <el-button type="danger" round size='small' v-if="row.status == 2">已驳回</el-button>
          <el-button type="success" round size='small' v-if="row.status == 3" @click="changeEnable(row.uid)">启用</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      value: true,
      screenVal: "游客组",
      tableData: [],
      multipleSelection: [], // 选择后的数据
      status: 0
    };
  },
  created() {
    this.getuser();
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    filterHandler(value, row, column) {
      this.screenVal = value;
      const property = column["property"];
      return row[property] === value;
    },
    async getuser() {
      this.tableData = [];
      const res = await this.axios.get("/api/backend/member");
      if (res.status_code === 200) {
        res.data.forEach(item => {
          this.tableData.push({
            uid: item.id,
            username: item.name,
            group: item.position.name,
            date: item.created_at,
            status: item.is_reviewed,
            email:item.email,
            region:item.region == null ? '' : item.region.name
          });
        });
      }
    },
    // 审核
    changExamine(id) {
      this.status = 1;
      this.putStatus(id);
    },
    // 禁用
    changeDisable(id) {
      this.status = 3;
      this.putStatus(id);
    },
    // 启用
    changeEnable(id) {
      this.status = 1;
      this.putStatus(id);
    },
    // 驳回
    changeReject(id) {
      this.status = 2;
      this.putStatus(id);
    },
    async putStatus(id) {
      const res = await this.axios.put(`/api/backend/member/${id}`, {
        is_reviewed: this.status
      });
      if (res.status_code === 200) {
        this.getuser();
      } else {
        this.$message.error(res.error);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.user {
  .user-header {
    width: 100%;
    height: 1.52rem /* 148/100 */;
    padding-left: 0.2rem /* 20/100 */;
    padding-top: 0.31rem /* 31/100 */;
    box-sizing: border-box;
    margin-bottom: 0.2rem /* 20/100 */;
    h2 {
      font-size: 0.24rem /* 24/100 */;
      color: #363a3f;
      font-weight: 400;
      margin-bottom: 0.27rem; /* 27/100 */
    }
    .el-input {
      width: 3.7rem /* 370/100 */;
      float: right;
      margin-right: 0.2rem; /* 20/100 */
    }
  }
  /deep/.el-table th {
    background-color: #edf2f6;
  }
}
</style>

